#the-canvas {
	position:fixed;
	top:0;
	left:0;
	z-index:-1;
}

.closeButton {
    position: relative;
    top: 10px;
    right: 10px;
    float: right;
    border: 1px dashed black;
    font-size: 3em;
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    z-index: 999;
}