.breaker {
    padding: 30px 0;
}


.play-now {
    display: inline-block;
    margin: 40px 0;
    padding: 20px 40px;
    background-color: nth($paired, 4);
    color: white !important;
    font-size: 2em;
    transition: all 0.2s ease-out;
}
.play-now:hover {
    background-color: white;
    color: nth($paired, 4) !important;
}

.date {
    color: $grey-color;
}

del {
    color: $grey-color-light;
}