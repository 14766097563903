
$posts-brand: cbrew('ylgn', 4);
$plato-brand: cbrew('pubu', 9);
$paired: cbrew('paired', 12);



.box {
    position: relative;
    background-color: nth($posts-brand, 1);
    color: $text-color;
    padding: 20px;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
}

.darkbox {
    background-color: $grey-color-dark;
}

.boxes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) ) ;
    grid-gap: 10px;
}

.box2 {
    grid-column: 3 / 6;
    grid-row: 2 / 3;
    z-index: 10;
    background-color: white;
}

.megabox {
    grid-column: 4 / 6;
    grid-row: 4 / 6;
    z-index: 10;
    background-color: white;
}

@include media-query(1200px) {
    .box2 {
        grid-column: 3 / 5;
    }
    .megabox {
        grid-column: inherit;
        grid-row: 2;
    }
}
@include media-query(1000px) {
    .box2 {
        grid-column: 2 / 4;
    }
    .megabox {
        grid-column: inherit;
        grid-row: 2;
    }
}
@include media-query($on-laptop) {
    .boxes {
        grid-template-columns: 1fr;
    }
    .box2 {
        grid-column: 1;
        grid-row: 3 / 3;
    }
    .megabox {
        display: none;
    }
}

.previousnext {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr;
}
.pnbox {
    display: block;
    padding: 40px;
    font-size: 150%;
}
.pnbox.prev {
    background-color: nth($plato-brand, 1);
}
.pnbox.next {
    background-color: nth($plato-brand, 2);
}

@media (max-width:800px)  {
    .previousnext {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 1fr;
    }
}


// boxes


.altbox:nth-child(12n) { background-color: nth($paired, 1); }
.altbox:nth-child(12n+1) { background-color: nth($paired, 2); }
.altbox:nth-child(12n+2) { background-color: nth($paired, 3); }
.altbox:nth-child(12n+3) { background-color: nth($paired, 4); }
.altbox:nth-child(12n+4) { background-color: nth($paired, 5); }
.altbox:nth-child(12n+5) { background-color: nth($paired, 6); }
.altbox:nth-child(12n+6) { background-color: nth($paired, 7); }
.altbox:nth-child(12n+7) { background-color: nth($paired, 8); }
.altbox:nth-child(12n+8) { background-color: nth($paired, 9); }
.altbox:nth-child(12n+9) { background-color: nth($paired, 10); }
.altbox:nth-child(12n+10) { background-color: nth($paired, 11); }
.altbox:nth-child(12n+11) { background-color: nth($paired, 12); }

.box a {
    background-color: white;
    padding: 2px;
}

// things inside boxes
.altbox:nth-child(12n+10) small {
    color: black;
}
.box small {
    color: black;
    background-color: white;
}

.box * {
    display: block;
}