
// @media (max-width:800px)  {
//     p {
//         line-height: 0.5em;
//     }
// }


// @include not-media-query($on-laptop) {
// 	h1.title {
// 		// background-color: darken($color: yellow, $amount: 10%);
// 		padding: 20px;
// 		font-size: 3em;
// 		&::before {
// 			content: "";
// 		}
// 	}
// }