$reds: cbrew('reds', 9);

.tags > ul {
	list-style: none;
	margin-left: 0px;
}
.tags > ul > li {
	display: inline;
}

.tags > ul > li {
	padding: 5px;
}
.tags > ul > li:nth-child(7n) { background-color: nth($reds, 1); }
.tags > ul > li:nth-child(7n+1) { background-color: nth($reds, 2); }
.tags > ul > li:nth-child(7n+2) { background-color: nth($reds, 3); }
.tags > ul > li:nth-child(7n+3) { background-color: nth($reds, 4); }
.tags > ul > li:nth-child(7n+4) { background-color: nth($reds, 5); }
.tags > ul > li:nth-child(7n+5) { background-color: nth($reds, 6); }
.tags > ul > li:nth-child(7n+6) { background-color: nth($reds, 7); }
.tags > ul > li:nth-child(7n+7) { background-color: nth($reds, 8); }
.tags > ul > li:nth-child(7n+8) { background-color: nth($reds, 9); }

.tags > ul > li:nth-child(7n) > a { color: darken(nth($reds, 1), 50%); }
.tags > ul > li:nth-child(7n+1) > a { color: darken(nth($reds, 2), 50%); }
.tags > ul > li:nth-child(7n+2) > a { color: darken(nth($reds, 3), 50%); }
.tags > ul > li:nth-child(7n+3) > a { color: darken(nth($reds, 4), 50%); }
.tags > ul > li:nth-child(7n+4) > a { color: darken(nth($reds, 5), 50%); }
.tags > ul > li:nth-child(7n+5) > a { color: darken(nth($reds, 6), 50%); }
.tags > ul > li:nth-child(7n+6) > a { color: darken(nth($reds, 7), 50%); }
.tags > ul > li:nth-child(7n+7) > a { color: darken(nth($reds, 8), 50%); }
.tags > ul > li:nth-child(7n+8) > a { color: darken(nth($reds, 9), 50%); }

/* tags.html */
.tag-header {
	background-color: nth($paired, 11);
	padding: 5px;
	margin-right: 20px;
}
#tags_container {
	p {
		margin: 20px 0;
	}
	ul {
		margin-top: 20px;
	}
}