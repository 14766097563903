$paired: cbrew('paired', 12);

.crj-breakdown-child > p > a {
    color: nth($reds, 4)
}

.ma-module-tag {
    display: flex;
    padding: 5px;
}
.ma-module-tag.GDD710 {
    background-color: nth($paired, 1);
    color: #333 !important;
}
.ma-module-tag.IGD720 {
    background-color: nth($paired, 2);
    color: white  !important;
}
.ma-module-tag.GDD730 {
    background-color: nth($paired, 7);
    color: #333  !important;
}
.ma-module-tag.IGD740 {
    background-color: nth($paired, 8);
    color: #333  !important;
}