//From https://github.com/Snugug/cbrew
//////////////////////////////
// Color Brewer Function
//  @name: Namespace of color set you want to use
//  @number: Number of steps you want.
//////////////////////////////

//////////////////////////////
// How To Use
//////////////////////////////
//#body {
//  // Create a variable and set its value to the cbrew function called with the
//  //  set you want and how many colors you want.
//  // This will give return a list of colors in order from the set you requested.
//  $bkg: cbrew('accent', 5);
//  
//  // To use a specific color, use the nth() function with your variable and the
//  //  color number you want (1 through how many colors you requested).
//  // The example below uses the 4th color of the Accent color set.
//  background: nth($bkg, 4);
//}

@function cbrew($name, $number) {
  // Initialize color
  $color: '';
  
  //////////////////////////////
  // Qualitative Colors
  //////////////////////////////
  // Accent namespace
  @if $name == 'accent' or $name == 'Accent' {
    $accent: rgb(127, 201, 127) rgb(190, 174, 212) rgb(253, 192, 134) rgb(244, 244, 153) rgb(56, 108, 176) rgb(240, 2, 127) rgb(191, 91, 23) rgb(102, 102, 102);
    $color: cbrew_iterator($accent, $number, 8);
  }
  
  // Dark
  @else if $name == 'dark' or $name == 'Dark'{
    $dark: rgb(27, 158, 119) rgb(217, 95, 2) rgb(117, 112, 179) rgb(231, 41, 138) rgb(102, 166, 30) rgb(230, 171, 2) rgb(166, 118, 2) rgb(102, 102, 102);
    $color: cbrew_iterator($dark, $number, 8);
  }
  
  // Paired
  @else if $name == 'paired' or $name == 'Paired' {
    $paired: rgb(166, 206, 227) rgb(31, 120, 180) rgb(178, 223, 138) rgb(51, 160, 44) rgb(251, 154, 153) rgb(227, 26, 28) rgb(253, 191, 111) rgb(255, 127, 0) rgb(202, 178, 214) rgb(106, 61, 154) rgb(255, 255, 153) rgb(177, 89, 40);
    $color: cbrew_iterator($paired, $number, 12);
  }
  
  // Pastel 1
  @else if $name == 'pastel1' or $name == 'Pastel1' or $name == 'pastel 1' or $name == 'Pastel 1' {
    $pastel1: rgb(251, 180, 174) rgb(179, 205, 227) rgb(204, 235, 197) rgb(222, 203, 228) rgb(254, 217, 166) rgb(255, 255, 204) rgb(229, 216, 189) rgb(253, 218, 236) rgb(242, 242, 242);
    $color: cbrew_iterator($pastel1, $number, 9);
  }
  
  // Pastel 2
  @else if $name == 'pastel2' or $name == 'Pastel2' or $name == 'pastel 2' or $name == 'Pastel 2' {
    $pastel2: rgb(27, 158, 119) rgb(217, 95, 2) rgb(117, 112, 179) rgb(231, 41, 138) rgb(102, 166, 30) rgb(230, 171, 2) rgb(166, 118, 2) rgb(102, 102, 102);
    $color: cbrew_iterator($pastel2, $number, 8);
  }
  
  // Set 1
  @else if $name== 'set1' or $name == 'Set1' or $name == 'set 1' or $name == 'Set 1' {
    $set1: rgb(228, 26, 28) rgb(55, 126, 184) rgb(77, 175, 74) rgb(152, 78, 163) rgb(225, 127, 0) rgb(255, 255, 51) rgb(166, 86, 40) rgb(247, 129, 191) rgb(153, 153, 153);
    $color: cbrew_iterator($set1, $number, 9);
  }
  
  // Set 2
  @else if $name== 'set2' or $name == 'Set2' or $name == 'set 2' or $name == 'Set 2' {
    $set2: rgb(102, 194, 165) rgb(252, 141, 98) rgb(141, 160, 203) rgb(231, 138, 195) rgb(166, 216, 84) rgb(255, 217, 47) rgb(229, 196, 148) rgb(179, 179, 179);
    $color: cbrew_iterator($set2, $number, 8);
  }
  
  // Set 3
  @else if $name== 'set3' or $name == 'Set3' or $name == 'set 3' or $name == 'Set 3' {
    $set3: rgb(141, 211, 199) rgb(255, 255, 179) rgb(190, 186, 218) rgb(251, 128, 114) rgb(128, 177, 211) rgb(253, 180, 98) rgb(179, 222, 105) rgb(252, 205, 229) rgb(217, 217, 217) rgb(188, 128, 189) rgb(204, 235, 197) rgb(255, 237, 111);
    $color: cbrew_iterator($set3, $number, 12);
  }
  
  //////////////////////////////
  // Sequential Colors
  //////////////////////////////
  // Reds
  @else if $name == 'reds' or $name == 'reds' {
    @if $number == 3 {
      $color: rgb(254, 224, 210) rgb(252, 146, 114) rgb(222, 45, 38);
    }
    @else if $number == 4 {
      $color: rgb(254, 229, 217) rgb(252, 174, 145) rgb(251, 106, 74) rgb(203, 24, 29);
    }
    @else if $number == 5 {
      $color: rgb(254, 229, 217) rgb(252, 174, 145) rgb(251, 106, 74) rgb(222, 45, 38) rgb(165, 15, 21);
    }
    @else if $number == 6 {
      $color: rgb(254, 229, 217) rgb(252, 187, 161) rgb(252, 146, 114) rgb(251, 106, 74) rgb(222, 45, 38) rgb(165, 15, 21);
    }
    @else if $number == 7 {
      $color: rgb(254, 229, 217) rgb(252, 187, 161) rgb(252, 146, 114) rgb(251, 106, 74) rgb(239, 59, 44) rgb(203, 24, 29) rgb(153, 0, 13);
    }
    @else if $number == 8 {
      $color: rgb(255, 245, 240) rgb(254, 224, 210) rgb(252, 187, 161) rgb(252, 146, 114) rgb(251, 106, 74) rgb(239, 59, 44) rgb(203, 24, 29) rgb(153, 0, 13);
    }
    @else if $number == 9 {
      $color: rgb(255, 245, 240) rgb(254, 224, 210) rgb(252, 187, 161) rgb(252, 146, 114) rgb(251, 106, 74) rgb(239, 59, 44) rgb(203, 24, 29) rgb(165, 15, 21) rgb(103, 0, 13);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // Oranges
  @else if $name == 'oranges' or $name == 'Oranges' {
    @if $number == 3 {
      $color: rgb(254, 230, 206) rgb(253, 174, 107) rgb(230, 85, 13);
    }
    @else if $number == 4 {
      $color: rgb(254, 237, 222) rgb(253, 190, 133) rgb(253, 141, 60) rgb(217, 71, 1);
    }
    @else if $number == 5 {
      $color: rgb(254, 237, 222) rgb(253, 190, 133) rgb(253, 141, 60) rgb(230, 85, 13) rgb(166, 54, 3);
    }
    @else if $number == 6 {
      $color: rgb(254, 237, 222) rgb(253, 208, 162) rgb(253, 174, 107) rgb(253, 141, 60) rgb(230, 85, 13) rgb(166, 54, 3);
    }
    @else if $number == 7 {
      $color: rgb(254, 237, 222) rgb(253, 208, 162) rgb(253, 174, 107) rgb(253, 141, 60) rgb(241, 105, 19) rgb(217, 72, 1) rgb(140, 45, 4);
    }
    @else if $number == 8 {
      $color: rgb(255, 245, 235) rgb(254, 230, 206) rgb(253, 208, 162) rgb(253, 174, 107) rgb(253, 141, 60) rgb(241, 105, 19) rgb(217, 72, 1) rgb(140, 45, 4);
    }
    @else if $number == 9 {
      $color: rgb(255, 245, 235) rgb(254, 230, 206) rgb(253, 208, 162) rgb(253, 174, 107) rgb(253, 141, 60) rgb(241, 105, 19) rgb(217, 72, 1) rgb(166, 54, 3) rgb(127, 39, 4) ;
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // Greens
  @else if $name == 'greens' or $name == 'Greens' {
    @if $number == 3 {
      $color: rgb(229, 245, 224) rgb(161, 217, 155) rgb(49, 163, 84);
    }
    @else if $number == 4 {
      $color: rgb(237, 248, 233) rgb(186, 228, 179) rgb(116, 196, 118) rgb(35, 139, 69);
    }
    @else if $number == 5 {
      $color: rgb(237, 248, 233) rgb(186, 228, 179) rgb(116, 196, 118) rgb(49, 163, 84) rgb(0, 109, 44);
    }
    @else if $number == 6 {
      $color: rgb(237, 248, 233) rgb(199, 233, 192) rgb(161, 217, 155) rgb(116, 196, 118) rgb(49, 163, 84) rgb(0, 109, 44);
    }
    @else if $number == 7 {
      $color: rgb(237, 248, 233) rgb(199, 233, 192) rgb(161, 217, 155) rgb(116, 196, 118) rgb(65, 171, 93) rgb(35, 139, 69) rgb(0, 90, 50);
    }
    @else if $number == 8 {
      $color: rgb(247, 252, 245) rgb(229, 245, 224) rgb(199, 233, 192) rgb(161, 217, 155) rgb(116, 196, 118) rgb(65, 171, 93) rgb(35, 139, 69) rgb(0, 90, 50);
    }
    @else if $number == 9 {
      $color: rgb(247, 252, 245) rgb(229, 245, 224) rgb(199, 233, 192) rgb(161, 217, 155) rgb(116, 196, 118) rgb(65, 171, 93) rgb(35, 139, 69) rgb(0, 109, 44) rgb(0, 68, 27);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // Blues
  @else if $name == 'blues' or $name == 'Blues' {
    @if $number == 3 {
      $color: rgb(222, 235, 247) rgb(158, 202, 225) rgb(49, 130, 189);
    }
    @else if $number == 4 {
      $color: rgb(239, 243, 255) rgb(189, 215, 231) rgb(107, 174, 214) rgb(33, 113, 181);
    }
    @else if $number == 5 {
      $color: rgb(239, 243, 255) rgb(189, 215, 231) rgb(107, 174, 214) rgb(49, 130, 189) rgb(8, 81, 156);
    }
    @else if $number == 6 {
      $color: rgb(239, 243, 255) rgb(198, 219, 239) rgb(158, 202, 225) rgb(107, 174, 214) rgb(49, 130, 189) rgb(8, 81, 156);
    }
    @else if $number == 7 {
      $color: rgb(239, 243, 255) rgb(198, 219, 239) rgb(158, 202, 225) rgb(158, 202, 225) rgb(107, 174, 214) rgb(66, 146, 198) rgb(33, 113, 181) rgb(8, 69, 148);
    }
    @else if $number == 8 {
      $color: rgb(247, 251, 255) rgb(222, 235, 247) rgb(198, 219, 239) rgb(198, 219, 239) rgb(158, 202, 225) rgb(107, 174, 214) rgb(66, 146, 198) rgb(33, 113, 181) rgb(8, 69, 148);
    }
    @else if $number == 9 {
      $color: rgb(247, 251, 255) rgb(222, 235, 247) rgb(198, 219, 247) rgb(198, 219, 239) rgb(158, 202, 225) rgb(107, 174, 214) rgb(66, 146, 198) rgb(33, 113, 181) rgb(8, 81, 156) rgb(8 48 107);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // Purples
  @else if $name == 'purples' or $name == 'Purples' {
    @if $number == 3 {
      $color: rgb(239, 237, 245) rgb(188, 189, 220) rgb(117, 107, 177);
    }
    @else if $number == 4 {
      $color: rgb(242, 240, 247) rgb(203, 201, 226) rgb(158, 154, 200) rgb(106, 81, 163);
    }
    @else if $number == 5 {
      $color: rgb(242, 240, 247) rgb(203, 201, 226) rgb(158, 154, 200) rgb(117, 107, 177) rgb(84, 39, 143);
    }
    @else if $number == 6 {
      $color: rgb(242, 240, 247) rgb(218, 218, 235) rgb(188, 189, 220) rgb(158, 154, 200) rgb(117, 107, 177) rgb(84, 39, 143);
    }
    @else if $number == 7 {
      $color: rgb(242, 240, 247) rgb(218, 218, 235) rgb(188, 189, 220) rgb(158, 154, 200) rgb(128, 125, 186) rgb(106, 81, 163) rgb(74, 20, 134);
    }
    @else if $number == 8 {
      $color: rgb(252, 251, 253) rgb(239, 237, 245) rgb(218, 218, 235) rgb(188, 189, 220) rgb(158, 154, 200) rgb(128, 125, 186) rgb(106, 81, 163) rgb(74, 20, 134);
    }
    @else if $number == 9 {
      $color: rgb(252, 251, 253) rgb(239, 237, 245) rgb(218, 218, 235) rgb(188, 189, 220) rgb(158, 154, 200) rgb(128, 125, 186) rgb(106, 81, 163) rgb(84, 39, 143) rgb(63, 0, 125);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // Greys
  @else if $name == 'greys' or $name == 'Greys' {
    @if $number == 3 {
      $color: rgb(240, 240, 240) rgb(189, 189, 189) rgb(99, 99, 99);
    }
    @else if $number == 4 {
      $color: rgb(247, 247, 247) rgb(204, 204, 204) rgb(150, 150, 150) rgb(82, 82, 82);
    }
    @else if $number == 5 {
      $color: rgb(247, 247, 247) rgb(204, 204, 204) rgb(150, 150, 150) rgb(99, 99, 99) rgb(37, 37, 37);
    }
    @else if $number == 6 {
      $color: rgb(247, 247, 247) rgb(217, 217, 217) rgb(189, 189, 189) rgb(150, 150, 150) rgb(99, 99, 99) rgb(37, 37, 37);
    }
    @else if $number == 7 {
      $color: rgb(247, 247, 247) rgb(217, 217, 217) rgb(189, 189, 189) rgb(150, 150, 150) rgb(115, 115, 115) rgb(82, 82, 82) rgb(37, 37, 37);
    }
    @else if $number == 8 {
      $color: rgb(255, 255, 255) rgb(240, 240, 240) rgb(217, 217, 217) rgb(189, 189, 189) rgb(150, 150, 150) rgb(115, 115, 115) rgb(82, 82, 82) rgb(37, 37, 37);
    }
    @else if $number == 9 {
      $color: rgb(255, 255, 255) rgb(240, 240, 240) rgb(217, 217, 217) rgb(189, 189, 189) rgb(150, 150, 150) rgb(115, 115, 115) rgb(82, 82, 82) rgb(37, 37, 37) rgb(0, 0, 0);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // BrBG
  @else if $name == 'brbg' or $name == 'BrBG' or $name == 'BrBg' {
    @if $number == 3 {
      $color: rgb(216, 179, 101) rgb(245, 245, 245) rgb(90, 180, 172);
    }
    @else if $number == 4 {
      $color: rgb(166, 97, 26) rgb(223, 194, 125) rgb(128, 205, 193) rgb(1, 133, 113);
    }
    @else if $number == 5 {
     $color: rgb(166, 97, 26) rgb(223, 194, 125) rgb(245, 245, 245) rgb(128, 205, 193) rgb(1, 133, 113);
    }
    @else if $number == 6 {
      $color: rgb(140, 81, 10) rgb(216, 179, 101) rgb(246, 232, 195) rgb(199, 234, 229) rgb(90, 180, 172) rgb(1, 102, 94);
    }
    @else if $number == 7 {
      $color: rgb(140, 81, 10) rgb(216, 179, 101) rgb(246, 232, 195) rgb(245, 245, 245) rgb(199, 234, 229) rgb(90, 180, 172) rgb(1, 102, 94);
    }
    @else if $number == 8 {
      $color: rgb(140, 81, 10) rgb(191, 129, 45) rgb(223, 194, 125) rgb(246, 232, 195) rgb(199, 234, 229) rgb(128, 205, 193) rgb(53, 151, 143) rgb(1, 102, 94);
    }
    @else if $number == 9 {
      $color: rgb(140, 81, 10) rgb(191, 129, 45) rgb(223, 194, 125) rgb(246, 232, 195) rgb(245, 245, 245) rgb(199, 234, 229) rgb(128, 205, 193) rgb(53, 151, 143) rgb(1, 102, 94);
    }
    @else if $number == 10 {
      $color: rgb(84, 48, 5) rgb(140, 81, 10) rgb(191, 129, 45) rgb(223, 194, 125) rgb(246, 232, 195) rgb(199, 234, 229) rgb(128, 205, 193) rgb(53, 151, 143) rgb(1, 102, 94) rgb(0, 60, 48);
    }
    @else if $number == 11 {
      $color: rgb(84, 48, 5) rgb(140, 81, 10) rgb(191, 129, 45) rgb(223, 194, 125) rgb(246, 232, 195) rgb(245, 245, 245) rgb(199, 234, 229) rgb(128, 205, 193) rgb(53, 151, 143) rgb(1, 102, 94) rgb(0, 60, 48);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // BuGn
  @else if $name == 'bugn' or $name == 'BuGn' {
    @if $number == 3 {
      $color: rgb(229, 245, 249) rgb(153, 216, 201) rgb(44, 162, 95);
    }
    @else if $number == 4 {
      $color: rgb(237, 248, 251) rgb(178, 226, 226) rgb(102, 194, 164) rgb(35, 139, 69);
    }
    @else if $number == 5 {
      $color: rgb(237, 248, 251) rgb(178, 226, 226) rgb(102, 194, 164) rgb(44, 162, 95) rgb(0, 109, 44);
    }
    @else if $number == 6 {
      $color: rgb(237, 248, 251) rgb(204, 236, 230) rgb(153, 216, 201) rgb(102, 194, 164) rgb(44, 162, 95) rgb(0, 109, 44);
    }
    @else if $number == 7 {
      $color: rgb(237, 248, 251) rgb(204, 236, 230) rgb(153, 216, 201) rgb(102, 194, 164) rgb(65, 174, 118) rgb(35, 139, 69) rgb(0, 88, 36);
    }
    @else if $number == 8 {
      $color: rgb(247, 252, 253) rgb(229, 245, 249) rgb(204, 236, 230) rgb(153, 216, 201) rgb(102, 194, 164) rgb(65, 174, 118) rgb(35, 139, 69) rgb(0, 88, 36);
    }
    @else if $number == 9 {
      $color: rgb(247, 252, 253) rgb(229, 245, 249) rgb(204, 236, 230) rgb(153, 216, 201) rgb(102, 194, 164) rgb(65, 174, 118) rgb(35, 139, 69) rgb(0, 109, 44) rgb(0, 68, 27);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // BuPu
  @else if $name == 'bupu' or $name == 'BuPu' {
    @if $number == 3 {
      $color: rgb(224, 236, 244) rgb(158, 188, 218) rgb(136, 86, 167);
    }
    @else if $number == 4 {
      $color: rgb(237, 248, 251) rgb(179, 205, 227) rgb(140, 150, 198) rgb(136, 65, 157);
    }
    @else if $number == 5 {
      $color: rgb(237, 248, 251) rgb(179, 205, 227) rgb(140, 150, 198) rgb(136, 86, 167) rgb(129, 15, 124);
    }
    @else if $number == 6 {
      $color: rgb(237, 248, 251) rgb(191, 211, 230) rgb(158, 188, 218) rgb(140, 150, 198) rgb(136, 86, 167) rgb(129, 15, 124);
    }
    @else if $number == 7 {
      $color: rgb(237, 248, 251) rgb(191, 211, 230) rgb(158, 188, 218) rgb(140, 150, 198) rgb(140, 107, 177) rgb(136, 65, 157) rgb(110, 1, 107);
    }
    @else if $number == 8 {
      $color: rgb(247, 252, 253) rgb(224, 236, 244) rgb(191, 211, 230) rgb(158, 188, 218) rgb(140, 150, 198) rgb(140, 107, 177) rgb(136, 65, 157) rgb(110, 1, 107);
    }
    @else if $number == 9 {
      $color: rgb(247, 252, 253) rgb(224, 236, 244) rgb(191, 211, 230) rgb(158, 188, 218) rgb(140, 150, 198) rgb(140, 107, 177) rgb(136, 65, 157) rgb(129, 15, 124) rgb(77, 0, 75);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // GnBu
  @else if $name == 'gnbu' or $name == 'GnBu' {
    @if $number == 3 {
      $color: rgb(224, 243, 219) rgb(168, 221, 181) rgb(67, 162, 202);
    }
    @else if $number == 4 {
      $color: rgb(240, 249, 232) rgb(186, 228, 188) rgb(123, 204, 196) rgb(43, 140, 190);
    }
    @else if $number == 5 {
      $color: rgb(240, 249, 232) rgb(186, 228, 188) rgb(123, 204, 196) rgb(67, 162, 202) rgb(8, 104, 172);
    }
    @else if $number == 6 {
      $color: rgb(240, 249, 232) rgb(204, 235, 197) rgb(168, 221, 181) rgb(123, 204, 196) rgb(67, 162, 202) rgb(8, 104, 172);
    }
    @else if $number == 7 {
      $color: rgb(240, 249, 232) rgb(204, 235, 197) rgb(168, 221, 181) rgb(123, 204, 196) rgb(78, 179, 211) rgb(43, 140, 190) rgb(8, 88, 158);
    }
    @else if $number == 8 {
      $color: rgb(247, 252, 240) rgb(224, 243, 219) rgb(204, 235, 197) rgb(168, 221, 181) rgb(123, 204, 196) rgb(78, 179, 211) rgb(43, 140, 190) rgb(8, 88, 158);
    }
    @else if $number == 9 {
      $color: rgb(247, 252, 240) rgb(224, 243, 219) rgb(204, 235, 197) rgb(168, 221, 181) rgb(123, 204, 196) rgb(78, 179, 211) rgb(43, 140, 190) rgb(8, 104, 172) rgb(8, 64, 129);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // OrRd
  @else if $name == 'orrd' or $name == 'OrRd' {
    @if $number == 3 {
      $color: rgb(254, 232, 200) rgb(253, 187, 132) rgb(227, 74, 51);
    }
    @else if $number == 4 {
      $color: rgb(254, 240, 217) rgb(253, 204, 138) rgb(252, 141, 89) rgb(215, 48, 31);
    }
    @else if $number == 5 {
      $color: rgb(254, 240, 217) rgb(253, 204, 138) rgb(252, 141, 89) rgb(227, 74, 51) rgb(179, 0, 0);
    }
    @else if $number == 6 {
      $color: rgb(254, 240, 217) rgb(253, 212, 158) rgb(253, 187, 132) rgb(252, 141, 89) rgb(227, 74, 51) rgb(179, 0, 0);
    }
    @else if $number == 7 {
      $color: rgb(254, 240, 217) rgb(253, 212, 158) rgb(253, 187, 132) rgb(252, 141, 89) rgb(239, 101, 72) rgb(215, 48, 31) rgb(153, 0, 0);
    }
    @else if $number == 8 {
      $color: rgb(255, 247, 236) rgb(254, 232, 200) rgb(253, 212, 158) rgb(253, 187, 132) rgb(252, 141, 89) rgb(239, 101, 72) rgb(215, 48, 31) rgb(153, 0, 0);
    }
    @else if $number == 9 {
      $color: rgb(255, 247, 236) rgb(254, 232, 200) rgb(253, 212, 158) rgb(253, 187, 132) rgb(252, 141, 89) rgb(239, 101, 72) rgb(215, 48, 31) rgb(179, 0, 0) rgb(127, 0, 0);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // PuBu
  @else if $name == 'pubu' or $name == 'PuBu'{
    @if $number == 3 {
      $color: rgb(236, 231, 242) rgb(166, 189, 219) rgb(43, 140, 190);
    }
    @else if $number == 4 {
      $color: rgb(241, 238, 246) rgb(189, 201, 225) rgb(116, 169, 207) rgb(5, 112, 176);
    }
    @else if $number == 5 {
      $color: rgb(241, 238, 246) rgb(189, 201, 225) rgb(116, 169, 207) rgb(43, 140, 190) rgb(4, 90, 141);
    }
    @else if $number == 6 {
      $color: rgb(241, 238, 246) rgb(208, 209, 230) rgb(166, 189, 219) rgb(116, 169, 207) rgb(43, 140, 190) rgb(4, 90, 141);
    }
    @else if $number == 7 {
      $color: rgb(241, 238, 246) rgb(208, 209, 230) rgb(166, 189, 219) rgb(116, 169, 207) rgb(54, 144, 192) rgb(5, 112, 176) rgb(3, 78, 123);
    }
    @else if $number == 8 {
      $color: rgb(255, 247, 251) rgb(236, 231, 242) rgb(208, 209, 230) rgb(166, 189, 219) rgb(116, 169, 207) rgb(54, 144, 192) rgb(5, 112, 176) rgb(3, 78, 123);
    }
    @else if $number == 9 {
      $color: rgb(255, 247, 251) rgb(236, 231, 242) rgb(208, 209, 230) rgb(166, 189, 219) rgb(116, 169, 207) rgb(54, 144, 192) rgb(5, 112, 176) rgb(4, 90, 141) rgb(2, 56, 88);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  
  // PuBuGn
  @else if $name == 'pubugn' or $name == 'PuBuGn' {
    @if $number == 3 {
      $color: rgb(236, 226, 240) rgb(166, 189, 219) rgb(28, 144, 153);
    }
    @else if $number == 4 {
      $color: rgb(246, 239, 247) rgb(189, 201, 225) rgb(103, 169, 207) rgb(2, 129, 138);
    }
    @else if $number == 5 {
      $color: rgb(246, 239, 247) rgb(189, 201, 225) rgb(103, 169, 207) rgb(28, 144, 153) rgb(1, 108, 89);
    }
    @else if $number == 6 {
      $color: rgb(246, 239, 247) rgb(208, 209, 230) rgb(166, 189, 219) rgb(103, 169, 207) rgb(28, 144, 153) rgb(1, 108, 89);
    }
    @else if $number == 7 {
      $color: rgb(246, 239, 247) rgb(208, 209, 230) rgb(166, 189, 219) rgb(103, 169, 207) rgb(54, 144, 192) rgb(2, 129, 138) rgb(1, 100, 80);
    }
    @else if $number == 8 {
      $color: rgb(255, 247, 251) rgb(236, 226, 240) rgb(208, 209, 230) rgb(166, 189, 219) rgb(103, 169, 207) rgb(54, 144, 192) rgb(2, 129, 138) rgb(1, 100, 80);
    }
    @else if $number == 9 {
      $color: rgb(255, 247, 251) rgb(236, 226, 240) rgb(208, 209, 230) rgb(166, 189, 219) rgb(103, 169, 207) rgb(54, 144, 192) rgb(2, 129, 138) rgb(1, 108, 89) rgb(1, 70, 54);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // PuRd
  @else if $name == 'purd' or $name == 'PuRd' {
    @if $number == 3 {
      $color: rgb(231, 225, 239) rgb(201, 148, 199) rgb(221, 28, 119);
    }
    @else if $number == 4 {
      $color: rgb(241, 238, 246) rgb(215, 181, 216) rgb(223, 101, 176) rgb(206, 18, 86);
    }
    @else if $number == 5 {
      $color: rgb(241, 238, 246) rgb(215, 181, 216) rgb(223, 101, 176) rgb(221, 28, 119) rgb(152, 0, 67);
    }
    @else if $number == 6 {
      $color: rgb(241, 238, 246) rgb(212, 185, 218) rgb(201, 148, 199) rgb(223, 101, 176) rgb(221, 28, 119) rgb(152, 0, 67);
    }
    @else if $number == 7 {
      $color: rgb(241, 238, 246) rgb(212, 185, 218) rgb(201, 148, 199) rgb(223, 101, 176) rgb(231, 41, 138) rgb(206, 18, 86) rgb(145, 0, 63);
    }
    @else if $number == 8 {
      $color: rgb(247, 244, 249) rgb(231, 225, 239) rgb(212, 185, 218) rgb(201, 148, 199) rgb(223, 101, 176) rgb(231, 41, 138) rgb(206, 18, 86) rgb(145, 0, 63);
    }
    @else if $number == 9 {
      $color: rgb(247, 244, 249) rgb(231, 225, 239) rgb(212, 185, 218) rgb(201, 148, 199) rgb(223, 101, 176) rgb(231, 41, 138) rgb(206, 18, 86) rgb(152, 0, 67) rgb(103, 0, 31);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // RdPu
  @else if $name == 'rdpu' or $name == 'rdpu' {
    @if $number == 3 {
      $color: rgb(253, 224, 221) rgb(250, 159, 181) rgb(197, 27, 138);
    }
    @else if $number == 4 {
      $color: rgb(254, 235, 226) rgb(251, 180, 185) rgb(247, 104, 161) rgb(174, 1, 126);
    }
    @else if $number == 5 {
      $color: rgb(254, 235, 226) rgb(251, 180, 185) rgb(247, 104, 161) rgb(197, 27, 138) rgb(122, 1, 119);
    }
    @else if $number == 6 {
      $color: rgb(254, 235, 226) rgb(252, 197, 192) rgb(250, 159, 181) rgb(247, 104, 161) rgb(197, 27, 138) rgb(122, 1, 119);
    }
    @else if $number == 7 {
      $color: rgb(254, 235, 226) rgb(252, 197, 192) rgb(250, 159, 181) rgb(247, 104, 161) rgb(221, 52, 151) rgb(174, 1, 126) rgb(122, 1, 119);
    }
    @else if $number == 8 {
      $color: rgb(255, 247, 243) rgb(253, 224, 221) rgb(252, 197, 192) rgb(250, 159, 181) rgb(247, 104, 161) rgb(221, 52, 151) rgb(174, 1, 126) rgb(122, 1, 119);
    }
    @else if $number == 9 {
      $color: rgb(255, 247, 243) rgb(253, 224, 221) rgb(252, 197, 192) rgb(250, 159, 181) rgb(247, 104, 161) rgb(221, 52, 151) rgb(174, 1, 126) rgb(122, 1, 119) rgb(73, 0, 106);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // YlGn
  @else if $name == 'ylgn' or $name == 'ylgn' {
    @if $number == 3 {
      $color: rgb(247, 252, 185) rgb(173, 221, 142) rgb(49, 163, 84);
    }
    @else if $number == 4 {
      $color: rgb(255, 255, 204) rgb(194, 230, 153) rgb(120, 198, 121) rgb(35, 132, 67);
    }
    @else if $number == 5 {
      $color: rgb(255, 255, 204) rgb(194, 230, 153) rgb(120, 198, 121) rgb(49, 163, 84) rgb(0, 104, 55);
    }
    @else if $number == 6 {
      $color: rgb(255, 255, 204) rgb(217, 240, 163) rgb(173, 221, 142) rgb(120, 198, 121) rgb(49, 163, 84) rgb(0, 104, 55);
    }
    @else if $number == 7 {
      $color: rgb(255, 255, 204) rgb(217, 240, 163) rgb(173, 221, 142) rgb(120, 198, 121) rgb(65, 171, 93) rgb(35, 132, 67) rgb(0, 90, 50);
    }
    @else if $number == 8 {
      $color: rgb(255, 255, 229) rgb(247, 252, 185) rgb(217, 240, 163) rgb(173, 221, 142) rgb(120, 198, 121) rgb(65, 171, 93) rgb(35, 132, 67) rgb(0, 90, 50);
    }
    @else if $number == 9 {
      $color: rgb(255, 255, 229) rgb(247, 252, 185) rgb(217, 240, 163) rgb(173, 221, 142) rgb(120, 198, 121) rgb(65, 171, 93) rgb(35, 132, 67) rgb(0, 104, 55) rgb(0, 69, 41);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // YlGnBu
  @else if $name == 'ylgnbu' or $name == 'YlGnBu' {
    @if $number == 3 {
      $color: rgb(237, 248, 177) rgb(127, 205, 187) rgb(44, 127, 184);
    }
    @else if $number == 4 {
      $color: rgb(255, 255, 204) rgb(161, 218, 180) rgb(65, 182, 196) rgb(34, 94, 168);
    }
    @else if $number == 5 {
      $color: rgb(255, 255, 204) rgb(161, 218, 180) rgb(65, 182, 196) rgb(44, 127, 184) rgb(37, 52, 148);
    }
    @else if $number == 6 {
      $color: rgb(255, 255, 204) rgb(199, 233, 180) rgb(127, 205, 187) rgb(65, 182, 196) rgb(44, 127, 184) rgb(37, 52, 148);
    }
    @else if $number == 7 {
      $color: rgb(255, 255, 204) rgb(199, 233, 180) rgb(127, 205, 187) rgb(65, 182, 196) rgb(29, 145, 192) rgb(34, 94, 168) rgb(12, 44, 132);
    }
    @else if $number == 8 {
      $color: rgb(255, 255, 217) rgb(237, 248, 177) rgb(199, 233, 180) rgb(127, 205, 187) rgb(65, 182, 196) rgb(29, 145, 192) rgb(34, 94, 168) rgb(12, 44, 132);
    }
    @else if $number == 9 {
      $color: rgb(255, 255, 217) rgb(237, 248, 177) rgb(199, 233, 180) rgb(127, 205, 187) rgb(65, 182, 196) rgb(29, 145, 192) rgb(34, 94, 168) rgb(37, 52, 148) rgb(8, 29, 88);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // YlOrBr
  @else if $name == 'ylorbr' or $name == 'YlOrBr' {
    @if $number == 3 {
      $color: rgb(255, 247, 188) rgb(254, 196, 79) rgb(217, 95, 14);
    }
    @else if $number == 4 {
      $color: rgb(255, 255, 212) rgb(254, 217, 142) rgb(254, 153, 41) rgb(204, 76, 2);
    }
    @else if $number == 5 {
      $color: rgb(255, 255, 212) rgb(254, 217, 142) rgb(254, 153, 41) rgb(217, 95, 14) rgb(153, 52, 4);
    }
    @else if $number == 6 {
      $color: rgb(255, 255, 212) rgb(254, 227, 145) rgb(254, 196, 79) rgb(254, 153, 41) rgb(217, 95, 14) rgb(153, 52, 4);
    }
    @else if $number == 7 {
      $color: rgb(255, 255, 212) rgb(254, 227, 145) rgb(254, 196, 79) rgb(254, 153, 41) rgb(236, 112, 20) rgb(204, 76, 2) rgb(140, 45, 4);
    }
    @else if $number == 8 {
      $color: rgb(255, 255, 229) rgb(255, 247, 188) rgb(254, 227, 145) rgb(254, 196, 79) rgb(254, 153, 41) rgb(236, 112, 20) rgb(204, 76, 2) rgb(140, 45, 4);
    }
    @else if $number == 9 {
      $color: rgb(255, 255, 229) rgb(255, 247, 188) rgb(254, 227, 145) rgb(254, 196, 79) rgb(254, 153, 41) rgb(236, 112, 20) rgb(204, 76, 2) rgb(153, 52, 4) rgb(102, 37, 6);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  // YlOrRd
  @else if $name == 'ylorrd' or $name == 'YlOrRd' {
    @if $number == 3 {
      $color: rgb(255, 237, 160) rgb(254, 178, 76) rgb(240, 59, 32);
    }
    @else if $number == 4 {
      $color: rgb(255, 255, 178) rgb(254, 204, 92) rgb(253, 141, 60) rgb(227, 26, 28);
    }
    @else if $number == 5 {
      $color: rgb(255, 255, 178) rgb(254, 204, 92) rgb(253, 141, 60) rgb(240, 59, 32) rgb(189, 0, 38);
    }
    @else if $number == 6 {
      $color: rgb(255, 255, 178) rgb(254, 217, 118) rgb(254, 178, 76) rgb(253, 141, 60) rgb(240, 59, 32) rgb(189, 0, 38);
    }
    @else if $number == 7 {
      $color: rgb(255, 255, 178) rgb(254, 217, 118) rgb(254, 178, 76) rgb(253, 141, 60) rgb(252, 78, 42) rgb(227, 26, 28) rgb(177, 0, 38);
    }
    @else if $number == 8 {
      $color: rgb(255, 255, 204) rgb(255, 237, 160) rgb(254, 217, 118) rgb(254, 178, 76) rgb(253, 141, 60) rgb(252, 78, 42) rgb(227, 26, 28) rgb(177, 0, 38);
    }
    @else if $number == 9 {
      $color: rgb(255, 255, 204) rgb(255, 237, 160) rgb(254, 217, 118) rgb(254, 178, 76) rgb(253, 141, 60) rgb(252, 78, 42) rgb(227, 26, 28) rgb(189, 0, 38) rgb(128, 0, 38);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 9 colors';
      }
      @return ERROR;
    }
  }
  
  //////////////////////////////
  // Diverging
  //////////////////////////////
  // PiYG
  @else if $name == 'piyg' or $name == 'PiYG' or $name == 'PIYG' or $name == 'PiYg'{
    @if $number == 3 {
      $color: rgb(233, 163, 201) rgb(247, 247, 247) rgb(161, 215, 106) ;
    }
    @else if $number == 4 {
      $color: rgb(208, 28, 139) rgb(241, 182, 218) rgb(184, 225, 134) rgb(77, 172, 38);
    }
    @else if $number == 5 {
      $color: rgb(208, 28, 139) rgb(241, 182, 218) rgb(247, 247, 247) rgb(184, 225, 134) rgb(77, 172, 38);
    }
    @else if $number == 6 {
      $color: rgb(197, 27, 125) rgb(233, 163, 201) rgb(253, 224, 239) rgb(230, 245, 208) rgb(161, 215, 106) rgb(77, 146, 33);
    }
    @else if $number == 7 {
      $color: rgb(197, 27, 125) rgb(233, 163, 201) rgb(253, 224, 239) rgb(247, 247, 247) rgb(230, 245, 208) rgb(161, 215, 106) rgb(77, 146, 33);
    }
    @else if $number == 8 {
      $color: rgb(197, 27, 125) rgb(222, 119, 174) rgb(241, 182, 218) rgb(253, 224, 239) rgb(230, 245, 208) rgb(184, 225, 134) rgb(127, 188, 65) rgb(77, 146, 33);
    }
    @else if $number == 9 {
      $color: rgb(197, 27, 125) rgb(222, 119, 174) rgb(241, 182, 218) rgb(253, 224, 239) rgb(247, 247, 247) rgb(230, 245, 208) rgb(184, 225, 134) rgb(127, 188, 65) rgb(77, 146, 33);
    }
    @else if $number == 10 {
      $color: rgb(142, 1, 82) rgb(197, 27, 125) rgb(222, 119, 174) rgb(241, 182, 218) rgb(253, 224, 239) rgb(230, 245, 208) rgb(184, 225, 134) rgb(127, 188, 65) rgb(77, 146, 33) rgb(39, 100, 25);
    }
    @else if $number == 11 {
      $color: rgb(142, 1, 82) rgb(197, 27, 125) rgb(222, 119, 174) rgb(241, 182, 218) rgb(253, 224, 239) rgb(247, 247, 247) rgb(230, 245, 208) rgb(184, 225, 134) rgb(127, 188, 65) rgb(77, 146, 33) rgb(39, 100, 25) ;
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // PRGn
  @else if $name == 'prgn' or $name == 'PRGn' or $name == 'PRGN' or $name == 'PrGn'{
    @if $number == 3 {
      $color: rgb(175, 141, 195) rgb(247, 247, 247) rgb(127, 191, 123);
    }
    @else if $number == 4 {
      $color: rgb(123, 50, 148) rgb(194, 165, 207) rgb(166, 219, 160) rgb(0, 136, 55);
    }
    @else if $number == 5 {
      $color: rgb(123, 50, 148) rgb(194, 165, 207) rgb(247, 247, 247) rgb(166, 219, 160) rgb(0, 136, 55);
    }
    @else if $number == 6 {
      $color: rgb(118, 42, 131) rgb(175, 141, 195) rgb(231, 212, 232) rgb(217, 240, 211) rgb(127, 191, 123) rgb(27, 120, 55);
    }
    @else if $number == 7 {
      $color: rgb(118, 42, 131) rgb(175, 141, 195) rgb(231, 212, 232) rgb(247, 247, 247) rgb(217, 240, 211) rgb(127, 191, 123) rgb(27, 120, 55);
    }
    @else if $number == 8 {
      $color: rgb(118, 42, 131) rgb(153, 112, 171) rgb(194, 165, 207) rgb(231, 212, 232) rgb(217, 240, 211) rgb(166, 219, 160) rgb(90, 174, 97) rgb(27, 120, 55);
    }
    @else if $number == 9 {
      $color: rgb(118, 42, 131) rgb(153, 112, 171) rgb(194, 165, 207) rgb(231, 212, 232) rgb(247, 247, 247) rgb(217, 240, 211) rgb(166, 219, 160) rgb(90, 174, 97) rgb(27, 120, 55);
    }
    @else if $number == 10 {
      $color: rgb(64, 0, 75) rgb(118, 42, 131) rgb(153, 112, 171) rgb(194, 165, 207) rgb(231, 212, 232) rgb(217, 240, 211) rgb(166, 219, 160) rgb(90, 174, 97) rgb(27, 120, 55) rgb(0, 68, 27);
    }
    @else if $number == 11 {
      $color: rgb(64, 0, 75) rgb(118, 42, 131) rgb(153, 112, 171) rgb(194, 165, 207) rgb(231, 212, 232) rgb(247, 247, 247) rgb(217, 240, 211) rgb(166, 219, 160) rgb(90, 174, 97) rgb(27, 120, 55) rgb(0, 68, 27);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // PuOr
  @else if $name == 'puor' or $name == 'PuOr' {
    @if $number == 3 {
      $color: rgb(241, 163, 64) rgb(247, 247, 247) rgb(153, 142, 195);
    }
    @else if $number == 4 {
      $color: rgb(230, 97, 1) rgb(253, 184, 99) rgb(178, 171, 210) rgb(94, 60, 153);
    }
    @else if $number == 5 {
      $color: rgb(230, 97, 1) rgb(253, 184, 99) rgb(247, 247, 247) rgb(178, 171, 210) rgb(94, 60, 153);
    }
    @else if $number == 6 {
      $color: rgb(179, 88, 6) rgb(241, 163, 64) rgb(254, 224, 182) rgb(216, 218, 235) rgb(153, 142, 195) rgb(84, 39, 136);
    }
    @else if $number == 7 {
      $color: rgb(179, 88, 6) rgb(241, 163, 64) rgb(254, 224, 182) rgb(247, 247, 247) rgb(216, 218, 235) rgb(153, 142, 195) rgb(84, 39, 136);
    }
    @else if $number == 8 {
      $color: rgb(179, 88, 6) rgb(224, 130, 20) rgb(253, 184, 99) rgb(254, 224, 182) rgb(216, 218, 235) rgb(178, 171, 210) rgb(128, 115, 172) rgb(84, 39, 136) ;
    }
    @else if $number == 9 {
      $color: rgb(179, 88, 6) rgb(224, 130, 20) rgb(253, 184, 99) rgb(254, 224, 182) rgb(247, 247, 247) rgb(216, 218, 235) rgb(178, 171, 210) rgb(128, 115, 172) rgb(84, 39, 136);
    }
    @else if $number == 10 {
      $color: rgb(127, 59, 8) rgb(179, 88, 6) rgb(224, 130, 20) rgb(253, 184, 99) rgb(254, 224, 182) rgb(216, 218, 235) rgb(178, 171, 210) rgb(128, 115, 172) rgb(84, 39, 136) rgb(45, 0, 75);
    }
    @else if $number == 11 {
      $color: rgb(127, 59, 8) rgb(179, 88, 6) rgb(224, 130, 20) rgb(253, 184, 99) rgb(254, 224, 182) rgb(247, 247, 247) rgb(216, 218, 235) rgb(178, 171, 210) rgb(128, 115, 172) rgb(84, 39, 136) rgb(45, 0, 75);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // RdBu
  @else if $name == 'rdbu' or $name == 'RdBu' {
    @if $number == 3 {
      $color: rgb(239, 138, 98) rgb(247, 247, 247) rgb(103, 169, 207);
    }
    @else if $number == 4 {
      $color: rgb(202, 0, 32) rgb(244, 165, 130) rgb(146, 197, 222) rgb(5, 113, 176);
    }
    @else if $number == 5 {
      $color: rgb(202, 0, 32) rgb(244, 165, 130) rgb(247, 247, 247) rgb(146, 197, 222) rgb(5, 113, 176);
    }
    @else if $number == 6 {
      $color: rgb(178, 24, 43) rgb(239, 138, 98) rgb(253, 219, 199) rgb(209, 229, 240) rgb(103, 169, 207) rgb(33, 102, 172);
    }
    @else if $number == 7 {
      $color: rgb(178, 24, 43) rgb(239, 138, 98) rgb(253, 219, 199) rgb(247, 247, 247) rgb(209, 229, 240) rgb(103, 169, 207) rgb(33, 102, 172);
    }
    @else if $number == 8 {
      $color: rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(209, 229, 240) rgb(146, 197, 222) rgb(67, 147, 195) rgb(33, 102, 172);
    }
    @else if $number == 9 {
      $color: rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(247, 247, 247) rgb(209, 229, 240) rgb(146, 197, 222) rgb(67, 147, 195) rgb(33, 102, 172);
    }
    @else if $number == 10 {
      $color: rgb(103, 0, 31) rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(209, 229, 240) rgb(146, 197, 222) rgb(67, 147, 195) rgb(33, 102, 172) rgb(5, 48, 97);
    }
    @else if $number == 11 {
      $color: rgb(103, 0, 31) rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(247, 247, 247) rgb(209, 229, 240) rgb(146, 197, 222) rgb(67, 147, 195) rgb(33, 102, 172) rgb(5, 48, 97);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // RdGy
  @else if $name == 'rdgy' or $name == 'RdGy' {
    @if $number == 3 {
      $color: rgb(239, 138, 98) rgb(255, 255, 255) rgb(153, 153, 153);
    }
    @else if $number == 4 {
      $color: rgb(202, 0, 32) rgb(244, 165, 130) rgb(186, 186, 186) rgb(64, 64, 64);
    }
    @else if $number == 5 {
      $color: rgb(202, 0, 32) rgb(244, 165, 130) rgb(255, 255, 255) rgb(186, 186, 186) rgb(64, 64, 64);
    }
    @else if $number == 6 {
      $color: rgb(178, 24, 43) rgb(239, 138, 98) rgb(253, 219, 199) rgb(224, 224, 224) rgb(153, 153, 153) rgb(77, 77, 77);
    }
    @else if $number == 7 {
      $color: rgb(178, 24, 43) rgb(239, 138, 98) rgb(253, 219, 199) rgb(255, 255, 255) rgb(224, 224, 224) rgb(153, 153, 153) rgb(77, 77, 77);
    }
    @else if $number == 8 {
      $color: rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(224, 224, 224) rgb(186, 186, 186) rgb(135, 135, 135) rgb(77, 77, 77);
    }
    @else if $number == 9 {
      $color: rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(255, 255, 255) rgb(224, 224, 224) rgb(186, 186, 186) rgb(135, 135, 135) rgb(77, 77, 77);
    }
    @else if $number == 10 {
      $color: rgb(103, 0, 31) rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(224, 224, 224) rgb(186, 186, 186) rgb(135, 135, 135) rgb(77, 77, 77) rgb(26, 26, 26);
    }
    @else if $number == 11 {
      $color: rgb(103, 0, 31) rgb(178, 24, 43) rgb(214, 96, 77) rgb(244, 165, 130) rgb(253, 219, 199) rgb(255, 255, 255) rgb(224, 224, 224) rgb(186, 186, 186) rgb(135, 135, 135) rgb(77, 77, 77) rgb(26, 26, 26);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // RdYlBu
  @else if $name == 'rdylbu' or $name == 'RdYlBu' {
    @if $number == 3 {
      $color: rgb(252, 141, 89) rgb(255, 255, 191) rgb(145, 191, 219);
    }
    @else if $number == 4 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(171, 217, 233) rgb(44, 123, 182);
    }
    @else if $number == 5 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(255, 255, 191) rgb(171, 217, 233) rgb(44, 123, 182);
    }
    @else if $number == 6 {
      $color: rgb(215, 48, 39) rgb(252, 141, 89) rgb(254, 224, 144) rgb(224, 243, 248) rgb(145, 191, 219) rgb(69, 117, 180);
    }
    @else if $number == 7 {
      $color: rgb(215, 48, 39) rgb(252, 141, 89) rgb(254, 224, 144) rgb(255, 255, 191) rgb(224, 243, 248) rgb(145, 191, 219) rgb(69, 117, 180);
    }
    @else if $number == 8 {
      $color: rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 144) rgb(224, 243, 248) rgb(171, 217, 233) rgb(116, 173, 209) rgb(69, 117, 180);
    }
    @else if $number == 9 {
      $color: rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 144) rgb(255, 255, 191) rgb(224, 243, 248) rgb(171, 217, 233) rgb(116, 173, 209) rgb(69, 117, 180);
    }
    @else if $number == 10 {
      $color: rgb(165, 0, 38) rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 144) rgb(224, 243, 248) rgb(171, 217, 233) rgb(116, 173, 209) rgb(69, 117, 180) rgb(49, 54, 149);
    }
    @else if $number == 11 {
      $color: rgb(165, 0, 38) rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 144) rgb(255, 255, 191) rgb(224, 243, 248) rgb(171, 217, 233) rgb(116, 173, 209) rgb(69, 117, 180) rgb(49, 54, 149);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // RdYlGn
  @else if $name == 'rdylgn' or $name == 'RdYlGn' {
    @if $number == 3 {
      $color: rgb(252, 141, 89) rgb(255, 255, 191) rgb(145, 207, 96);
    }
    @else if $number == 4 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(166, 217, 106) rgb(26, 150, 65);
    }
    @else if $number == 5 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(255, 255, 191) rgb(166, 217, 106) rgb(26, 150, 65);
    }
    @else if $number == 6 {
      $color: rgb(215, 48, 39) rgb(252, 141, 89) rgb(254, 224, 139) rgb(217, 239, 139) rgb(145, 207, 96) rgb(26, 152, 80);
    }
    @else if $number == 7 {
      $color: rgb(215, 48, 39) rgb(252, 141, 89) rgb(254, 224, 139) rgb(255, 255, 191) rgb(217, 239, 139) rgb(145, 207, 96) rgb(26, 152, 80);
    }
    @else if $number == 8 {
      $color: rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(217, 239, 139) rgb(166, 217, 106) rgb(102, 189, 99) rgb(26, 152, 80);
    }
    @else if $number == 9 {
      $color: rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(255, 255, 191) rgb(217, 239, 139) rgb(166, 217, 106) rgb(102, 189, 99) rgb(26, 152, 80);
    }
    @else if $number == 10 {
      $color: rgb(165, 0, 38) rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(217, 239, 139) rgb(166, 217, 106) rgb(102, 189, 99) rgb(26, 152, 80) rgb(0, 104, 55);
    }
    @else if $number == 11 {
      $color: rgb(165, 0, 38) rgb(215, 48, 39) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(255, 255, 191) rgb(217, 239, 139) rgb(166, 217, 106) rgb(102, 189, 99) rgb(26, 152, 80) rgb(0, 104, 55);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // Spectral
  @else if $name == 'spectral' or $name == 'Spectral' {
    @if $number == 3 {
      $color: rgb(252, 141, 89) rgb(255, 255, 191) rgb(153, 213, 148);
    }
    @else if $number == 4 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(171, 221, 164) rgb(43, 131, 186);
    }
    @else if $number == 5 {
      $color: rgb(215, 25, 28) rgb(253, 174, 97) rgb(255, 255, 191) rgb(171, 221, 164) rgb(43, 131, 186);
    }
    @else if $number == 6 {
      $color: rgb(213, 62, 79) rgb(252, 141, 89) rgb(254, 224, 139) rgb(230, 245, 152) rgb(153, 213, 148) rgb(50, 136, 189);
    }
    @else if $number == 7 {
      $color: rgb(213, 62, 79) rgb(252, 141, 89) rgb(254, 224, 139) rgb(255, 255, 191) rgb(230, 245, 152) rgb(153, 213, 148) rgb(50, 136, 189);
    }
    @else if $number == 8 {
      $color: rgb(213, 62, 79) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(230, 245, 152) rgb(171, 221, 164) rgb(102, 194, 165) rgb(50, 136, 189);
    }
    @else if $number == 9 {
      $color: rgb(213, 62, 79) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(255, 255, 191) rgb(230, 245, 152) rgb(171, 221, 164) rgb(102, 194, 165) rgb(50, 136, 189);
    }
    @else if $number == 10 {
      $color: rgb(158, 1, 66) rgb(213, 62, 79) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(230, 245, 152) rgb(171, 221, 164) rgb(102, 194, 165) rgb(50, 136, 189) rgb(94, 79, 162);
    }
    @else if $number == 11 {
      $color: rgb(158, 1, 66) rgb(213, 62, 79) rgb(244, 109, 67) rgb(253, 174, 97) rgb(254, 224, 139) rgb(255, 255, 191) rgb(230, 245, 152) rgb(171, 221, 164) rgb(102, 194, 165) rgb(50, 136, 189) rgb(94, 79, 162);
    }
    @else {
      @if $number < 3 {
        @warn 'You must have at lease 3 colors';
      }
      @else {
        @warn $number 'has a maximum of 11 colors';
      }
      @return ERROR;
    }
  }
  
  // If color set is not found, throw error.
  @else {
    @warn 'The color set you wanted was not found';
    @return ERROR;
  }
  
  @return $color;
}

//////////////////////////////
// Itteration Function
//  Qualitative sets are easy and all itterations can be found using
//   a for loop, so abstracted that out.
//////////////////////////////
@function cbrew_iterator($series, $number, $itterations) {
  @if $number >= 3 and $number <= $itterations {
    $color: '';
    @for $i from 1 through $number {
      @if $i == 1 {
        $color: nth($series, $i);
      }
      @else {
        $color: join($color, nth($series, $i));
      }
    }
    @return $color;
  }
  @else {
    @if $number < 3 {
      @warn 'You must have at lease 3 colors';
    }
    @else {
      @warn $number 'has a maximum of' $itterations 'colors';
    }
    @return ERROR;
  }
}
